<template>
    <div>
        <div
            v-if="loadingConnections"
            class="layout justify-center py-5">
            <loader :label="loadingConnectionsMessage" />
        </div>
        <div v-else>
            <v-alert
                :value="errors.length"
                class="mb-5">
                <p>
                    There were errors while creating assets.  Please try again or contact support if
                    the problem continues.
                </p>
                <ul>
                    <li
                        v-for="(error, index) in errors"
                        :key="`${index}_${error}`">
                        {{ error }}
                    </li>
                </ul>
            </v-alert>

            <h2>Connect Business Manager Assets</h2>
            <p class="mb-5">
                Looking good!  To deploy this playbook we’re going to need to connect
                a few things.  These settings will live with this account so you’ll
                only need to do this once.  For each asset you can choose to have
                BuyerBridge create a new one or you can choose one that already exists
                in your Business Manager.
            </p>

            <!-- Asset Container Start -->
            <v-form
                v-model="valid"
                class="asset-container mb-5">
                <div
                    v-if="!allAssetsSetup"
                    class="asset-row asset-header">
                    <div />
                    <div class="layout">
                        <div class="text-xs-center flex">
                            <div>
                                New
                            </div>
                            <a
                                href="#"
                                class="d-block"
                                @click.prevent="selectAll('new')">
                                select all
                            </a>
                        </div>
                        <div class="text-xs-center flex">
                            <div>
                                Existing
                            </div>
                            <a
                                href="#"
                                class="d-block"
                                @click.prevent="selectAll('existing')">
                                select all
                            </a>
                        </div>
                    </div>
                    <div />
                </div>

                <!-- Ad Account Start -->
                <div
                    v-if="hasAdAccountSetup"
                    class="asset-row">
                    <div>
                        <h3>
                            Ad Account
                        </h3>
                        <p>
                            Ad accounts are core to Meta Advertising plays.
                            This is where we’ll be deploying everything.
                        </p>
                    </div>
                    <div class="asset-row-radio-group">
                        <v-radio-group
                            v-if="adAccountSetupIncomplete"
                            v-model="newAdAccount">
                            <div class="layout">
                                <v-radio
                                    :value="true"
                                    color="primary"
                                    class="flex layout justify-center" />
                                <v-radio
                                    :value="false"
                                    color="primary"
                                    class="flex layout justify-center" />
                            </div>
                        </v-radio-group>
                        <div
                            v-else
                            class="text-xs-center">
                            <status-icon size="25" />
                            <span class="pl-2">Configured!</span>
                        </div>
                    </div>
                    <div>
                        <v-autocomplete
                            v-if="adAccountSetupIncomplete"
                            v-model="selectedAdAccount"
                            :items="adAccounts"
                            :loading="loadingAdAccounts"
                            item-text="name"
                            item-value="id"
                            label="Select ad account"
                            :disabled="newAdAccount"
                            :rules="newAdAccount ? [] : requiredRules"
                            class="styled-field" />
                        <div
                            v-else
                            class="text-xs-center">
                            <a
                                :href="dealerFacebookAdAccountUrl"
                                target="_blank">
                                {{ dealerFacebookAdAccount }}
                            </a>
                        </div>
                    </div>
                </div>
                <!-- Ad Account End -->

                <!-- Facebook Pixel Start -->
                <div
                    v-if="hasPixelSetup"
                    class="asset-row">
                    <div>
                        <h3>
                            Facebook Pixel
                        </h3>
                        <p>
                            The pixel is critical for tracking, including leads,
                            and building up retargeting &amp; lookalike audiences
                            from your website traffic.
                        </p>
                    </div>
                    <div class="asset-row-radio-group">
                        <v-radio-group
                            v-if="pixelSetupIncomplete"
                            v-model="newPixel">
                            <div class="layout">
                                <v-radio
                                    :value="true"
                                    color="primary"
                                    class="flex layout justify-center" />
                                <v-radio
                                    :value="false"
                                    color="primary"
                                    class="flex layout justify-center" />
                            </div>
                        </v-radio-group>
                        <div
                            v-else
                            class="text-xs-center">
                            <status-icon size="25" />
                            <span class="pl-2">Configured!</span>
                        </div>
                    </div>
                    <div>
                        <v-autocomplete
                            v-if="pixelSetupIncomplete"
                            v-model="selectedPixel"
                            :items="pixels"
                            :loading="loadingPixels"
                            item-text="name"
                            item-value="id"
                            label="Select pixel"
                            :disabled="newPixel"
                            :rules="newPixel ? [] : requiredRules"
                            class="styled-field" />
                        <div
                            v-else
                            class="text-xs-center">
                            <a
                                :href="dealerFacebookPixelUrl"
                                target="_blank">
                                {{ dealerFacebookPixel }}
                            </a>
                        </div>
                    </div>
                </div>
                <!-- Facebook Pixel End -->


                <!-- Offline Event Set Start -->
                <div
                    v-if="hasOfflineEventSetSetup"
                    class="asset-row">
                    <div>
                        <h3>
                            Offline Event Set
                        </h3>
                        <p>
                            Offline events allow you to match offline customers
                            to users who saw or clicked on an ad.  These are essential
                            to guaging performance
                        </p>
                    </div>
                    <div class="asset-row-radio-group">
                        <v-radio-group
                            v-if="offlineEventSetSetupIncomplete"
                            v-model="newOfflineEventSet">
                            <div class="layout">
                                <v-radio
                                    :value="true"
                                    color="primary"
                                    class="flex layout justify-center" />
                                <v-radio
                                    :value="false"
                                    color="primary"
                                    class="flex layout justify-center" />
                            </div>
                        </v-radio-group>
                        <div
                            v-else
                            class="text-xs-center">
                            <status-icon size="25" />
                            <span class="pl-2">Configured!</span>
                        </div>
                    </div>
                    <div>
                        <v-autocomplete
                            v-if="offlineEventSetSetupIncomplete"
                            v-model="selectedOfflineEventSet"
                            :items="offlineEventSets"
                            :loading="loadingOfflineEventSets"
                            item-text="name"
                            item-value="id"
                            label="Select event set"
                            light
                            required
                            :disabled="newOfflineEventSet"
                            :rules="newOfflineEventSet ? [] : requiredRules"
                            class="styled-field" />
                        <div
                            v-else
                            class="text-xs-center">
                            <a
                                :href="dealerFacebookOfflineEventSetUrl"
                                target="_blank">
                                {{ dealerFacebookOfflineEventSet }}
                            </a>
                        </div>
                    </div>
                </div>
                <!-- Offline Event Set End -->


                <!-- Product Catalog Start -->
                <div
                    v-if="hasProductCatalogSetup"
                    class="asset-row">
                    <div>
                        <h3>
                            Product Catalog
                        </h3>
                        <p :class="{ 'mb-2' : productCatalogSetupIncomplete }">
                            Product catalogs are core in enabling Dynamic Product Ads
                            on Facebook along with Instagram Shopping ads.  They also
                            offer other value-added features beyond the vertical catalog
                            types.
                        </p>
                        <!-- @todo Revisit data sources when we can support external inventory -->
                        <!-- <p v-if="productCatalogSetupIncomplete">
                            <styled-tooltip position="top">
                                <template #content>
                                    This setting determines if BuyerBridge will be used for inventory
                                    and will automatically setup a data source to retrieve inventory from
                                    BuyerBridge once it's available.  If you are adding inventory to the
                                    catalog yourself leave this unchecked.
                                </template>
                                <v-checkbox
                                    v-model="configureProductCatalogDataSource"
                                    color="primary"
                                    label="Use BuyerBridge inventory"
                                    class="styled-checkbox" />
                            </styled-tooltip>
                        </p> -->
                    </div>
                    <div class="asset-row-radio-group">
                        <v-radio-group
                            v-if="productCatalogSetupIncomplete"
                            v-model="newProductCatalog">
                            <div class="layout">
                                <v-radio
                                    :value="true"
                                    color="primary"
                                    class="flex layout justify-center" />
                                <styled-tooltip
                                    position="top"
                                    class="flex layout justify-center align-center">
                                    <template #content>
                                        BuyerBridge does not <em>currently</em> support using
                                        existing product catalogs.
                                    </template>
                                    <div class="grey--text caption">
                                        N/A
                                    </div>
                                </styled-tooltip>
                                <!-- <v-radio
                                    :value="false"
                                    color="primary"
                                    class="flex layout justify-center"  /> -->
                            </div>
                        </v-radio-group>
                        <div
                            v-else
                            class="text-xs-center">
                            <status-icon size="25" />
                            <span class="pl-2">Configured!</span>
                        </div>
                    </div>
                    <div>
                        <!-- <v-autocomplete
                            v-if="productCatalogSetupIncomplete"
                            v-model="selectedProductCatalog"
                            :items="productCatalogs"
                            :loading="loadingProductCatalogs"
                            item-text="name"
                            item-value="id"
                            label="Select product catalog"
                            :disabled="newProductCatalog"
                            :rules="newProductCatalog ? [] : requiredRules"
                            class="styled-field" /> -->
                        <div
                            v-if="!productCatalogSetupIncomplete"
                            class="text-xs-center">
                            <a
                                :href="dealerFacebookProductCatalogUrl"
                                target="_blank">
                                {{ dealerFacebookProductCatalog }}
                            </a>
                        </div>
                    </div>
                </div>
                <!-- Product Catalog End -->



                <!-- Vehicle Catalog Start -->
                <div
                    v-if="hasAutomotiveCatalogSetup"
                    class="asset-row">
                    <div>
                        <h3>
                            Vehicle Catalog
                        </h3>
                        <p
                            :class="{ 'mb-2' : productCatalogSetupIncomplete }">
                            Vehicle catalogs are specific to the automotive &amp;
                            marine industries and enable Automotive Inventory Ads,
                            Click-2-Marketplace Ads and Markeplace autos listings.
                        </p>
                        <!-- @todo Revisit data sources when we can support external inventory -->
                        <!-- <p v-if="productCatalogSetupIncomplete">
                            <styled-tooltip position="top">
                                <template #content>
                                    This setting determines if BuyerBridge will be used for inventory
                                    and will automatically setup a data source to retrieve inventory from
                                    BuyerBridge  once it's available.  If you are adding inventory to the
                                    catalog yourself leave this unchecked.
                                </template>
                                <v-checkbox
                                    v-model="configureAutomotiveCatalogDataSource"
                                    color="primary"
                                    label="Use BuyerBridge inventory"
                                    class="styled-checkbox" />
                            </styled-tooltip>
                        </p> -->
                    </div>
                    <div class="asset-row-radio-group">
                        <v-radio-group
                            v-if="automotiveCatalogSetupIncomplete"
                            v-model="newAutomotiveCatalog">
                            <div class="layout">
                                <v-radio
                                    :value="true"
                                    color="primary"
                                    class="flex layout justify-center" />
                                <styled-tooltip
                                    position="top"
                                    class="flex layout justify-center align-center">
                                    <template #content>
                                        BuyerBridge does not <em>currently</em> support using
                                        existing vehicle catalogs.
                                    </template>
                                    <div class="grey--text caption">
                                        N/A
                                    </div>
                                </styled-tooltip>
                                <!-- <v-radio
                                    :value="false"
                                    color="primary"
                                    class="flex layout justify-center"  /> -->
                            </div>
                        </v-radio-group>
                        <div
                            v-else
                            class="text-xs-center">
                            <status-icon size="25" />
                            <span class="pl-2">Configured!</span>
                        </div>
                    </div>
                    <div>
                        <!-- <v-autocomplete
                            v-if="automotiveCatalogSetupIncomplete"
                            v-model="selectedAutomotiveCatalog"
                            :items="automotiveCatalogs"
                            :loading="loadingAutomotiveCatalogs"
                            item-text="name"
                            item-value="id"
                            label="Select vehicle catalog"
                            :disabled="newAutomotiveCatalog"
                            :rules="newAutomotiveCatalog ? [] : requiredRules"
                            class="styled-field" /> -->
                        <div
                            v-if="!automotiveCatalogSetupIncomplete"
                            class="text-xs-center">
                            <a
                                :href="dealerFacebookAutomotiveCatalogUrl"
                                target="_blank">
                                {{ dealerFacebookAutomotiveCatalog }}
                            </a>
                        </div>
                    </div>
                </div>
                <!-- Vechile Catalog End -->
            </v-form>
            <!--  Asset container End -->



            <div class="layout align-center">
                <back-button
                    @click="$store.dispatch('goToPreviousStep')" />
                <styled-button
                    class="ml-auto"
                    :disabled="!valid"
                    @click="onContinue">
                    CONTINUE
                </styled-button>
            </div>
        </div>
    </div>
</template>

<script>
import { requiredRules } from '../../../../helpers/validationRules';
import Loader from '../../../globals/Loader';
import BackButton from '../../../globals/BackButton';
import StyledTooltip from '../../../globals/StyledTooltip';
import StyledButton from '../../../globals/StyledButton';
import StatusIcon from '../../../globals/StatusIcon';
import { mapGetters, mapState } from 'vuex';
import { get } from 'lodash';
import sleep from '../../../../helpers/sleep';

export default {
    name: 'FacebookBusinessManagerAssets',
    components: {
        BackButton,
        StatusIcon,
        StyledButton,
        StyledTooltip,
        Loader
    },
    props: {
        previousStep: {
            type: Number,
            default: null
        },
        nextStep: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            loadingConnectionsMessage: '',
            retries: 3,
            valid: false,
            requiredRules,
            adAccounts: [],
            selectedAdAccount: null,
            loadingAdAccounts: false,
            pixels: [],
            selectedPixel: null,
            loadingPixels: false,
            offlineEventSets: [],
            selectedOfflineEventSet: null,
            loadingOfflineEventSets: false,
            productCatalogs: [],
            selectedProductCatalog: null,
            loadingProductCatalogs: false,
            automotiveCatalogs: [],
            selectedAutomotiveCatalog: null,
            loadingAutomotiveCatalogs: false,
            newAdAccount: true,
            newPixel: true,
            newOfflineEventSet: true,
            newProductCatalog: true,
            newAutomotiveCatalog: true,
            loadingConnections: false,
            configureAutomotiveCatalogDataSource: true,
            configureProductCatalogDataSource: true,
            errors: []
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer
        }),
        ...mapGetters([
            'dealerVehicleCount',
            'currentStepActiveFeatures',
            'applicableFeatures',
            'requiredFeatures',
            'onboardingStatus',
            'dealerFacebookAdAccount',
            'dealerFacebookAdAccountUrl',
            'dealerFacebookPixel',
            'dealerFacebookPixelUrl',
            'dealerFacebookOfflineEventSet',
            'dealerFacebookOfflineEventSetUrl',
            'dealerFacebookProductCatalog',
            'dealerFacebookProductCatalogUrl',
            'dealerFacebookAutomotiveCatalog',
            'dealerFacebookAutomotiveCatalogUrl'
        ]),
        hasAdAccountSetup() {
            return this.requiredFeatures.includes('facebook_ad_account');
        },
        adAccountSetupIncomplete() {
            if (!this.hasAdAccountSetup) {
                return null;
            }
            const adAccountStatus = this.onboardingStatus.find(feature => feature.feature === 'facebook_ad_account');

            if (!adAccountStatus) {
                return true;
            }

            return ['missing_initial_setup', 'missing_dependencies'].includes(adAccountStatus.status);
        },
        hasPixelSetup() {
            return this.requiredFeatures.includes('facebook_pixel');
        },
        pixelSetupIncomplete() {
            if (!this.hasPixelSetup) {
                return null;
            }

            const pixelStatus = this.onboardingStatus.find(feature => feature.feature === 'facebook_pixel');

            if (!pixelStatus) {
                return true;
            }

            return ['missing_initial_setup', 'missing_dependencies'].includes(pixelStatus.status);
        },
        hasOfflineEventSetSetup() {
            return this.requiredFeatures.includes('facebook_offline_event_set');
        },
        offlineEventSetSetupIncomplete() {
            if (!this.hasOfflineEventSetSetup) {
                return null;
            }

            const offlineEventSetStatus = this.onboardingStatus.find(feature => feature.feature === 'facebook_offline_event_set');

            if (!offlineEventSetStatus) {
                return true;
            }

            return ['missing_initial_setup', 'missing_dependencies'].includes(offlineEventSetStatus.status);
        },
        hasProductCatalogSetup() {
            return this.requiredFeatures.includes('facebook_product_catalog');
        },
        productCatalogSetupIncomplete() {
            if (!this.hasProductCatalogSetup) {
                return false;
            }

            const productCatalogStatus = this.onboardingStatus.find(feature => feature.feature === 'facebook_product_catalog');

            if (!productCatalogStatus) {
                return true;
            }

            return ['missing_initial_setup', 'missing_dependencies'].includes(productCatalogStatus.status);
        },
        hasAutomotiveCatalogSetup() {
            return this.requiredFeatures.includes('facebook_automotive_catalog');
        },
        automotiveCatalogSetupIncomplete() {
            if (!this.hasAutomotiveCatalogSetup) {
                return false;
            }

            const automotiveCatalogStatus = this.onboardingStatus.find(feature => feature.feature === 'facebook_automotive_catalog');

            if (!automotiveCatalogStatus) {
                return true;
            }

            return ['missing_initial_setup', 'missing_dependencies'].includes(automotiveCatalogStatus.status);
        },
        hasCustomConversionsSetup() {
            return this.requiredFeatures.includes('facebook_custom_conversion');
        },
        customConversionsSetupIncomplete() {
            if (!this.hasCustomConversionsSetup) {
                return null;
            }

            const customConversionStatus = this.onboardingStatus.find(feature => feature.feature === 'facebook_custom_conversion');

            if (!customConversionStatus) {
                return true;
            }

            return ['missing_initial_setup', 'missing_dependencies'].includes(customConversionStatus.status);
        },
        allAssetsSetup() {
            return (
                (this.hasAdAccountSetup ? !this.adAccountSetupIncomplete : true) &&
                (this.hasPixelSetup ? !this.pixelSetupIncomplete : true) &&
                (this.hasOfflineEventSetSetup ? !this.offlineEventSetSetupIncomplete : true) &&
                (this.hasProductCatalogSetup ? !this.productCatalogSetupIncomplete : true) &&
                (this.hasAutomotiveCatalogSetup ? !this.automotiveCatalogSetupIncomplete : true)
            );
        }
    },
    created() {
        this.loadBusinessManagerData();
    },
    methods: {
        loadBusinessManagerData() {
            if (this.hasAdAccountSetup) {
                this.getAdAccounts();
            }
            if (this.hasPixelSetup) {
                this.getPixels();
            }
            if (this.hasOfflineEventSetSetup) {
                this.getOfflineEventSets();
            }
            if (this.hasProductCatalogSetup) {
                this.getProductCatalogs();
            }
            if (this.hasAutomotiveCatalogSetup) {
                this.getAutomotiveCatalogs();
            }
        },
        async getAdAccounts() {
            const response = await this.$apiRepository.getAgencyAdAccounts(this.currentDealer.agency_id);
            this.adAccounts = response.data.data;
            this.loadingAdAccounts = false;
        },
        async getPixels() {
            const response = await this.$apiRepository.getAgencyPixels(this.currentDealer.agency_id);
            this.pixels = response.data.data;
            this.loadingPixels = false;
        },
        async getOfflineEventSets() {
            const response = await this.$apiRepository.getAgencyOfflineEventSets(this.currentDealer.agency_id);
            this.offlineEventSets = response.data.data;
            this.loadingOfflineEventSets = false;
        },
        async getProductCatalogs() {
            const response = await this.$apiRepository.getAgencyProductCatalogs(this.currentDealer.agency_id);
            this.productCatalogs = response.data.data;
            this.loadingProductCatalogs = false;
        },
        async getAutomotiveCatalogs() {
            const response = await this.$apiRepository.getAgencyAutomotiveCatalogs(this.currentDealer.agency_id);
            this.automotiveCatalogs = response.data.data;
            this.loadingAutomotiveCatalogs = false;
        },
        async onContinue() {
            // If everything is setup there's nothing to do
            if (this.allAssetsSetup) {
                return this.$store.dispatch('goToNextStep');
            }

            this.loadingConnections = true;
            this.errors = [];

            this.loadingConnectionsMessage = 'Setting up assets.  Sit tight, this can sometimes take a while!';
            await sleep(1000);

            // Ensure the ad account is created first as it's used in other opps
            if (
                (this.hasAdAccountSetup && this.adAccountSetupIncomplete)
                && this.newAdAccount
            ) {
                await this.createNewAdAccount();
                this.loadingConnectionsMessage = 'Your new ad account is setup!  Moving along!';
            }
            else if (
                (this.hasAdAccountSetup && this.adAccountSetupIncomplete)
                && !this.newAdAccount
            ) {
                await this.connectExistingAdAccount();
                this.loadingConnectionsMessage = 'Your existing ad account is now powered by BuyerBridge!';
            }

            // Setup the pixel next
            if (this.hasPixelSetup && this.pixelSetupIncomplete && this.newPixel) {
                await this.createNewPixel();
                this.loadingConnectionsMessage = 'Congrats!  You\'re now the owner of a fresh new pixel';
            }
            else if (this.hasPixelSetup && this.pixelSetupIncomplete && !this.newPixel) {
                await this.connectExistingPixel();
                this.loadingConnectionsMessage = 'Ad violia!  We just hooked up your exsting pixel.';
            }

            const operations = [];

            if (this.hasOfflineEventSetSetup && this.offlineEventSetSetupIncomplete) {
                operations.push(
                    this.newOfflineEventSet ? this.createNewOfflineEventSet : this.connectExistingOfflineEventSet
                );
            }

            if (this.hasProductCatalogSetup && this.productCatalogSetupIncomplete) {
                operations.push(
                    this.newProductCatalog ? this.createNewProductCatalog : this.connectExistingProductCatalog
                );
            }

            if (this.hasAutomotiveCatalogSetup && this.automotiveCatalogSetupIncomplete) {
                operations.push(
                    this.newAutomotiveCatalog ? this.createNewAutomotiveCatalog : this.connectExistingAutomotiveCatalog
                );
            }

            // Kick off an interval since we don't always know how long the async opps will take
            const miscMessages = [
                'New we\'re rolling!  Processing remaining assets',
                'Crunching some numbers and other good stuff',
                'Things are really happening now!'
            ];
            let messageIndex = 0;
            const messagingInterval = setInterval(() => {
                // Stop once we're at the last message
                if ((messageIndex + 1) >= miscMessages.length) {
                    return  clearInterval(messagingInterval);
                }
                this.loadingConnectionsMessage = miscMessages[messageIndex];
                messageIndex++;
            }, 2000);

            // Execute all operations and map them into Promise.all
            await Promise.all(
                operations.map(op => op())
            );

            clearInterval(messagingInterval);

            // Custom conversions depend on the ad account, offline events & pixel
            if (this.hasCustomConversionsSetup && this.customConversionsSetupIncomplete) {
                await this.createCustomConversions();
                this.loadingConnectionsMessage = 'Creating custom conversions to tie things up here!';
            }

            // If the dealer has vehicles and a catalog has been setup with a native data source
            // populate it with inventory!
            if (
                this.dealerVehicleCount > 0 &&
                (
                    (this.hasProductCatalogSetup && this.configureProductCatalogDataSource) ||
                    (this.hasAutomotiveCatalogSetup && this.configureAutomotiveCatalogDataSource)
                )
             ) {
                this.$store.dispatch('processDealerInventory');
            }

            // If the operation completed without errors continue!
            if (!this.errors.length) {
                await sleep(1000);
                this.loadingConnectionsMessage = 'And that\'s it!  The setup is complete.  Moving along!';
                await sleep(2000);
                this.$store.dispatch('goToNextStep');
            }
            // If there's errors update the onboarding status to reflect whatever
            // completed from the steps above
            else {
                this.loadingConnectionsMessage = 'Errr.  There might be some issues 😬.  Stand by while we recheck everything.';
                await this.$store.dispatch('updateOnboardingStatus', {
                    features: this.currentStepActiveFeatures
                });
            }

            this.loadingConnections = false;

        },
        async createNewAdAccount() {
            try {
                await this.$http.post(`/dealers/${this.currentDealer.id}/create-ad-account`);
            } catch(error) {
                this.handleError('Error creating new ad account', error);
            }
        },
        async connectExistingAdAccount() {
            try {
                await this.$http.post('facebook_ad_account_annotations', {
                    dealer_id: this.currentDealer.id,
                    facebook_ad_account_id: this.selectedAdAccount,
                });
            } catch(error) {
                this.handleError('Error connecting existing ad account', error);
            }
        },
        async createNewPixel() {
            try {
                await this.$http.postRetry(`/dealers/${this.currentDealer.id}/run-action/create-fb-pixel`, null, this.retries, 500);
            } catch(error) {
                this.handleError('Error creating new Facebook pixel', error);
            }
        },
        async connectExistingPixel() {
            try {
                await this.$http.post('facebook_pixel_annotations', {
                    dealer_id: this.currentDealer.id,
                    facebook_pixel_id: this.selectedPixel,
                });
            } catch(error) {
                this.handleError('Error connecting existing Facebook pixel', error);
            }
        },
        async createNewOfflineEventSet() {
            try {
                await this.$http.postRetry(`/dealers/${this.currentDealer.id}/run-action/create-fb-offline-event-set`, null, this.retries, 500);
            } catch(error) {
                this.handleError('Error creating new offline event set', error);
            }
        },
        async connectExistingOfflineEventSet() {
            try {
                await this.$http.post('facebook_offline_event_set_annotations', {
                    dealer_id: this.currentDealer.id,
                    facebook_offline_event_set_id: this.selectedOfflineEventSet,
                    name: `${this.currentDealer.name} Offline Events`,
                });
            } catch(error) {
                this.handleError('Error connecting existing offline event set', error);
            }
        },
        async createNewProductCatalog() {
            try {
                await this.$http.post(`/dealers/${this.currentDealer.id}/setup-catalog`, {
                    catalog_type: 'product',
                    configure_data_source: this.configureProductCatalogDataSource
                });
            } catch(error) {
                this.handleError('Error creating new product catalog', error);
            }
        },
        async connectExistingProductCatalog() {
            try {
                await this.$http.post(`/dealers/${this.currentDealer.id}/setup-catalog`, {
                    catalog_type: 'product',
                    configure_data_source: this.configureProductCatalogDataSource,
                    catalog_id: this.selectedProductCatalog
                });
            } catch(error) {
                this.handleError('Error connecting existing product catalog', error);
            }
        },
        async createNewAutomotiveCatalog() {
            try {
                await this.$http.post(`/dealers/${this.currentDealer.id}/setup-catalog`, {
                    catalog_type: 'automotive',
                    configure_data_source: this.configureAutomotiveCatalogDataSource
                });
            } catch(error) {
                this.handleError('Error creating new product catalog', error);
            }
        },
        async connectExistingAutomotiveCatalog() {
            try {
                await this.$http.post(`/dealers/${this.currentDealer.id}/setup-catalog`, {
                    catalog_type: 'automotive',
                    configure_data_source: this.configureAutomotiveCatalogDataSource,
                    catalog_id: this.selectedAutomotiveCatalog
                });
            } catch(error) {
                this.handleError('Error connecting existing automotive catalog', error);
            }
        },
        async createCustomConversions() {
            try {
                await this.$http.post(`/dealers/${this.currentDealer.id}/run-action/create-fb-custom-conversions`);
            } catch(error) {
                this.handleError('Error creating custom conversions', error);
            }
        },
        handleError(label, error) {
            // If the API has returned messages display them to the user
            const messages = get(error, 'response.data.error.messages', null) || [];
            if (messages.length) {
                this.errors = [
                    ...this.errors,
                    ...messages
                        .filter(message => !message.includes('on line'))
                        .map(message => `${label}: ${message}`)
                ];
            }
            // Otherwise just append in the label
            else {
                this.errors.push(label);
            }

            console.error(label, error);
        },
        selectAll(type) {
            if (type === 'new') {
                this.newAdAccount = true;
                this.newPixel = true;
                this.newOfflineEventSet = true;
                this.newProductCatalog = true;
                this.newAutomotiveCatalog = true;
            } else {
                this.newAdAccount = false;
                this.newPixel = false;
                this.newOfflineEventSet = false;
                this.newProductCatalog = false;
                this.newAutomotiveCatalog = false;
            }
        }
    }
};
</script>

<style lang="scss">
.asset-container {
    .asset-row {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        & > div {
            &:nth-child(1) {
                width: 50%;
            }
            &:nth-child(2) {
                width: 23%;
            }
            &:nth-child(3) {
                width: 27%;
            }
        }
    }
    .asset-row-radio-group {
        width: 200px;
        .v-input--selection-controls {
            padding: 0;
            margin: 0;
            .v-input--selection-controls__input {
                margin: 0;
            }
            .v-input__control {
                width: 100%;
            }
            .v-input__slot {
                margin: 0;
            }
            .v-messages {
                display: none;
            }
            .v-label {
                display: none;
            }
        }
        .v-input--radio-group--column {
            .v-radio {
                margin: 0;
            }
        }
    }
}
</style>
